import React, { Component } from 'react';
import classes from './App.module.css';
import  PostLists from './components/pp/blog/PostsList';

class App extends Component {

  render() {
    return (
      <div className={classes.app}>
        <PostLists />
      </div>
    );
  }
}

export default App;
