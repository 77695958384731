import React, { Component } from 'react';
import classes from './PostsList.module.css';


class PostsList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        posts: [],
        post: null,
        singleView: false
      };
    }
  
    viewPost = (post, e) => {
      this.setState({
        post: post,
        singleView: true
      });
    }
  
    viewPosts = (e) => {
      this.setState({
        post: null,
        singleView: false
      });
    }
  
    componentDidMount() {
      fetch("https://pp.douglaspetrin.com/b/wp-json/wp/v2/posts?_embed")
        .then(res => res.json())
        .then(
          (posts) => {
            this.setState({
              isLoaded: true,
              posts: posts
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, posts, post, singleView } = this.state;
  
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else if (singleView) {
        return (
          <div>
            <button onClick={this.viewPosts}>All Posts</button>
  
            <div className={classes.post}>
              <h1>{post.title.rendered}</h1>
              <div dangerouslySetInnerHTML={{__html: post.content.rendered}} />
            </div>
          </div>
        );
      } else {
        return (
            <div className={classes.post}>
            <h1>Positive Player Academy</h1>
            <ul>
              {posts.map(post => (
                <li className={classes.li} key={post.id}>
                  <h2 onClick={(e) => this.viewPost(post, e)}>{post.title.rendered}</h2>
                  
                  <a href={post.link}>
                  <img className={classes.img} src={post._embedded['wp:featuredmedia']['0'].source_url}></img>
                  </a>
                  
                </li>
              ))}
            </ul>

          </div>
        );
      }
    }
  }

  export default PostsList;